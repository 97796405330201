/*
 * @Author: suchuanzhong
 * @Date: 2023-01-31 17:50:02
 * @LastEditors: suchuanzhong
 * @LastEditTime: 2023-08-11 14:34:59
 * @FilePath: \cp-class-advisor-center\src\utils\const.js
 * @Description:
 *
 */
export const starOptions = [
  { label: '无', value: 0 },
  { label: '一星级', value: 1 },
  { label: '二星级', value: 2 },
  { label: '三星级', value: 3 },
  { label: '四星级', value: 4 },
  { label: '五星级', value: 5 },
  { label: '六星级', value: 6 },
  { label: '七星级', value: 7 },
  { label: '八星级', value: 8 },
  { label: '九星级', value: 9 },
  { label: '十星级', value: 10 },
];
export const dayOfWeekOptions = [
  { label: '周一', value: 1 },
  { label: '周二', value: 2 },
  { label: '周三', value: 3 },
  { label: '周四', value: 4 },
  { label: '周五', value: 5 },
  { label: '周六', value: 6 },
  { label: '周日', value: 7 },
];

export const languageOptions = [
  { label: '中文普通话', value: '中文普通话' },
  { label: '英语', value: '英语' },
  { label: '日语', value: '日语' },
  { label: '法语', value: '法语' },
  { label: '德语', value: '德语' },
  { label: '俄语', value: '俄语' },
  { label: '韩语', value: '韩语' },
  { label: '粤语', value: '粤语' },
  { label: '泰语', value: '泰语' },
  { label: '印尼语', value: '印尼语' },
  { label: '西班牙语', value: '西班牙语' },
  { label: '新加坡英语', value: '新加坡英语' },
  { label: '阿拉伯语', value: '阿拉伯语' },
  { label: '意大利语', value: '意大利语' },
  { label: '其他', value: '其他' },
];

export const teachingStylesOptions = [
  { label: '认真严谨', value: '认真严谨' },
  { label: '活泼有趣', value: '活泼有趣' },
  { label: '善于拓展', value: '善于拓展' },
  { label: '亲切自然', value: '亲切自然' },
  { label: '经验丰富', value: '经验丰富' },
  { label: '善于引导', value: '善于引导' },
];

export const genderOptions = [
  { label: '男', value: 'M' },
  { label: '女', value: 'F' },
  { label: '不确定', value: 'U' },
];

export const sectionTimeOptions = [
  { label: '提前二周', value: -14 },
  { label: '提前一周', value: -7 },
  { label: '提前三天', value: -3 },
  { label: '提前二天', value: -2 },
  { label: '提前一天', value: -1 },
  { label: '推后一天', value: 1 },
  { label: '推后二天', value: 2 },
  { label: '推后三天', value: 3 },
  { label: '推后一周', value: 7 },
  { label: '推后二周', value: 14 },
];

export const RolesMap = {
  TEACHING_STAFF: 'TEACHING_STAFF', // 教务
  TEACHING_STAFF_ADMIN: 'TEACHING_STAFF_ADMIN', // 教务主管
  ADMIN: 'ADMIN', // 系统管理员
  CLASS_ADMIN: 'CLASS_ADMIN', // 班主任
};
export const changeClassification = [
  {
    label: '学生原因',
    value: '学生原因',
    children: [
      { label: '学生低龄，学习不稳定', value: '学生低龄，学习不稳定' },
      { label: '学生校内安排冲突', value: '学生校内安排冲突' },
      { label: '学生课外安排冲突', value: '学生课外安排冲突' },
      { label: '学生学习兴趣', value: '学生学习兴趣' },
      { label: '学生健康原因', value: '学生健康原因' },
      { label: '班课时间无法匹配', value: '班课时间无法匹配' },
      { label: '只上固定课程', value: '只上固定课程' },
      { label: '转竞品尝试', value: '转竞品尝试' },
    ],
  },
  {
    label: '家庭原因',
    value: '家庭原因',
    children: [
      { label: '常住地变更', value: '常住地变更' },
      { label: '家长时间安排冲突', value: '家长时间安排冲突' },
      { label: '家庭假期安排冲突', value: '家庭假期安排冲突' },
    ],
  },
  {
    label: '其他',
    value: '其他',
    children: [
      { label: '沉睡学员转冻结', value: '沉睡学员转冻结' },
      { label: '退费挽单成功', value: '退费挽单成功' },
      { label: '授课老师无法满足', value: '授课老师无法满足' },
      { label: '现有原因无法选择', value: '现有原因无法选择' },
      { label: '家长未说明具体原因', value: '家长未说明具体原因' },
    ],
  },
];

export const StudyPurposeEnum = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
};

export const StudyPurposeEnumText = {
  [StudyPurposeEnum.A]: '开拓升学/职业前景',
  [StudyPurposeEnum.B]: '了解中华文化',
  [StudyPurposeEnum.C]: '培养兴趣爱好',
  [StudyPurposeEnum.D]: '熟练与他人交流',
  [StudyPurposeEnum.E]: '听，读，写',
  [StudyPurposeEnum.F]: '备考HSK/YCT/IB/A-Level',
};
